import {
    GET_ALL_USER,
    CURREN_USER_DATA,
    SET_SKILLS_USER_DATA,
    SET_USER_PAGE_HISTORY,
    SET_MULTI_SKILLS_USER_DATA,
    SET_CURRENT_PORTFOLIO_DATA,
    SET_CURRENT_CERTIFICATE_DATA,
    SET_MULTI_TRANSPORT_USER_DATA,
    SET_TRANSPORT_USER_DATA,
    SET_ALL_USERS_CITY,
    SET_ALL_TASKS_CITY
} from '../types/types'

export const setUserData = (value) => {
    return {
        type: GET_ALL_USER,
        payload: { data: value }
    }
}

export const setCurrentUserData = (value) => {
    return {
        type: CURREN_USER_DATA,
        payload: { data: value }
    }
}

export const setSkillsUserData = (value) => {
    return {
        type: SET_SKILLS_USER_DATA,
        payload: { data: value }
    }
}

export const setMutiSkillsUserData = (value) => {
    return {
        type: SET_MULTI_SKILLS_USER_DATA,
        payload: { data: value }
    }
}

export const setUserPageHistoryAction = (value) => {
    return {
        type: SET_USER_PAGE_HISTORY,
        payload: { data: value }
    }
}


export const setCurrentPortfolioData = (value) => {
    return {
        type: SET_CURRENT_PORTFOLIO_DATA,
        payload: { data: value }
    }
}

export const setCurrentCertificateData = (value) => {
    return {
        type: SET_CURRENT_CERTIFICATE_DATA,
        payload: { data: value }
    }
}

export const setTransportUserData = (value) => {
    return {
        type: SET_TRANSPORT_USER_DATA,
        payload: { data: value }
    }
}

export const setMutiTransportUserData = (value) => {
    return {
        type: SET_MULTI_TRANSPORT_USER_DATA,
        payload: { data: value }
    }
}

export const setAllUsersCityData = (value) => {
    return {
        type: SET_ALL_USERS_CITY,
        payload: { data: value }
    }
}

export const setAllTasksCityData = (value) => {
    return {
        type: SET_ALL_TASKS_CITY,
        payload: { data: value }
    }
}