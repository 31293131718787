// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
// import calenderReducer from "./calenderReducer";
// import emailReducer from "./email/";
// import chatReducer from "./chatReducer";
// import chatReducer from "./chat/";
// import contactsReducer from "./contacts/";
// import todoReducer from "./todo/";
import customizer from "./customizer/";
import loginReducer from "./login/loginReducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import LoaderReducer from "./loader/loaderReducer";
import DashboardReducer from "./dashboard/dashboardReducer";
import CategoryReducers from "./category/categoryReducer";
import UserReducer from "./user/UserReducer";
import ForgetPassword from "../reducers/forgetPassword/forgetPassword";
import SetNewPassword from "../reducers/setPassword/setpasswordReducer";
import SkillsReducers from "../reducers/skills/skillsReducer";
import VoucherReducers from "../reducers/vouchers/voucherReducer";
import searchBarReducers from "./searchBarReducer/searchBarReducer";
import TaskReducer from "./task/TaskReducer";
import TaskCancellationRequest from "./task/taskCancellation";
import Reasons from "./reasons/reasons";
import Inquiries from "./inquiries/inquiries";
import MarkerImageReducer from "./markerIMage/markerImageReducer";
import SettingReducers from "./Settings/settingReducer";
import TransactionReducer from "./transaction/transactionReducer";
import TrackingReducers from "../reducers/tracking/trackingReducer";
import Partners from "./partners/partners";
import PlansReducers from "./plans/PlansReducers";
import Roles from "./roles/roles";
import Employees from "./employees/employees";
import UserPermissions from "./userPermissions/UserPermissionsReducer";
import SelectedSideMenu from "./sideMenuDropdown.js/sideMenuDropdown";
import VideoSettingsReducer from "./videoSettings/videoSettingsReducer";

const rootReducer = combineReducers({
  toastr: toastrReducer, // <- Mounted at toastr.
  customizer: customizer,
  login: loginReducer,
  loader: LoaderReducer,
  dashboard: DashboardReducer,
  category: CategoryReducers,
  user: UserReducer,
  forgetPassword: ForgetPassword,
  setNewPassword: SetNewPassword,
  skills: SkillsReducers,
  filteredData: searchBarReducers,
  task: TaskReducer,
  taskCancellationRequest: TaskCancellationRequest,
  markerImage: MarkerImageReducer,
  setting: SettingReducers,
  transaction: TransactionReducer,
  vouchers: VoucherReducers,
  tracking: TrackingReducers,
  partners: Partners,
  plans: PlansReducers,
  roles: Roles,
  employees: Employees,
  reasons: Reasons,
  inquiries: Inquiries,
  userPermissions: UserPermissions,
  selectedSideMenu: SelectedSideMenu,
  videoSettings: VideoSettingsReducer,
});

export default rootReducer;
