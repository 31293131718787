import { SET_SELECTED_SIDE_MENU } from "../../actions/types/sideMenuDropdownTypes.js";

const initialState = {
  selectedSideMenu: {
    sideMenuIndex: null,
    subMenuIndex: null,
  },
};

const SelectedSideMenu = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_SIDE_MENU:
      return { ...state, selectedSideMenu: action.payload.data };
    default:
      return state;
  }
};

export default SelectedSideMenu;
