import { SET_VIDEO_SETTINGS_DATA } from "../../actions/types/videoSettingsTypes.js";

const initialState = {
  videoSettingsData: {},
};

const VideoSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO_SETTINGS_DATA:
      return { ...state, videoSettingsData: action.payload.data };
    default:
      return state;
  }
};

export default VideoSettingsReducer;
