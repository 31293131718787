import {GET_ALL_SKILLS,SET_ALL_LANGUAGE,GET_ALL_TRANSPORTS} from '../types/types';


export const setSkillsData = (value) =>{
    return {
        type: GET_ALL_SKILLS,
        payload: { data: value }
    }
}

export const setLangulageData = (value) =>{
    return {
        type: SET_ALL_LANGUAGE,
        payload: { data: value }
    }
}

export const setTransportsData = (value) =>{
    return {
        type: GET_ALL_TRANSPORTS,
        payload: { data: value }
    }
}