import { SET_ALL_REQUEST } from "../../actions/types/task/taskCancellation";

const initialState = {
  taskCancellationRequestList: [],
};

const TaskCancellationRequest = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_REQUEST:
      return { ...state, taskCancellationRequestList: action.payload.data };

    default:
      return state;
  }
};

export default TaskCancellationRequest;
