export const GET_ALL_REASONS_LIST = "GET_ALL_REASONS_LIST";
export const SET_ALL_REASONS_LIST = "SET_ALL_REASONS_LIST";
export const SET_ALL_REASONS_LIST_TYPE_C = "SET_ALL_REASONS_LIST_TYPE_C";
export const SET_ALL_REASONS_LIST_TYPE_CAT = "SET_ALL_REASONS_LIST_TYPE_CAT";
export const SET_ALL_REASONS_LIST_TYPE_D = "SET_ALL_REASONS_LIST_TYPE_D";

export const GET_ALL_CANCEL_TASK_FEEDBACK_LIST =
  "GET_ALL_CANCEL_TASK_FEEDBACK_LIST";
export const SET_ALL_CANCEL_TASK_FEEDBACK_LIST =
  "SET_ALL_CANCEL_TASK_FEEDBACK_LIST";

export const GET_ALL_DELETE_TASK_FEEDBACK_LIST =
  "GET_ALL_DELETE_TASK_FEEDBACK_LIST";
export const SET_ALL_DELETE_TASK_FEEDBACK_LIST =
  "SET_ALL_DELETE_TASK_FEEDBACK_LIST";

export const GET_ALL_RATINGS_LIST = "GET_ALL_RATINGS_LIST";
export const SET_ALL_RATINGS_LIST = "SET_ALL_RATINGS_LIST";

export const SET_ALL_TOTAL_RATINGS_LIST = "SET_ALL_TOTAL_RATINGS_LIST";

export const GET_RATINGS_DOWNLOAD = "GET_RATINGS_DOWNLOAD";
export const SET_RATINGS_DOWNLOAD = "SET_RATINGS_DOWNLOAD";
