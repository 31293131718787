import { SET_ALL_TRANSACTION } from '../../actions/types/types'

const initialState={    
    allTransaction:[]
}


const TransactionReducer=(state=initialState,action)=>{
    switch (action.type){
        
        case SET_ALL_TRANSACTION:
            return{...state,allTransaction:action.payload.data}
        default:
            return state;
    }
}

export default TransactionReducer;