import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import FullPageLayout from "../layouts/fullpageLayout";

const PublicRoute = ({ isAuthenticated, component: Component, ...rest }) => (
    <Route {...rest} component={(props) => (
               
        isAuthenticated ? (
            <Redirect to='/user' />
        ) : (
                <FullPageLayout>
                    <Component {...props} />
                </FullPageLayout>
            )
    )}
    />
);

const mapStateToProps = state => {
    const isAuthenticated = state.login.loginFlag;
    return { isAuthenticated }
};

export default connect(mapStateToProps)(PublicRoute);

