import {
  SET_ALL_GENERAL_INQUIRIES_LIST,
  SET_ALL_REQUESTL_INQUIRIES_LIST,
  SET_ALL_TASK_INQUIRIES_LIST,
  SET_UNREAD_INUIRIES_COUNT
} from "../types/inquiries/inquiries";

export const setGeneralInquiriesList = (value) => {
  return {
    type: SET_ALL_GENERAL_INQUIRIES_LIST,
    payload: { data: value },
  };
};
export const setRequestInquiriesList = (value) => {
  return {
    type: SET_ALL_REQUESTL_INQUIRIES_LIST,
    payload: { data: value },
  };
};

export const setTaskInquiriesList = (value) => {
  return {
    type: SET_ALL_TASK_INQUIRIES_LIST,
    payload: { data: value },
  };
};

export const setUnreadInquiriesCount = (value) => {
  return {
    type: SET_UNREAD_INUIRIES_COUNT,
    payload: { data: value },
  };
}
