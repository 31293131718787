export const SET_ALL_PARTNER_LIST = "GET_ALL_PARTNER_LIST";

export const SET_PARTNER_TABLE_LOADER = "SET_TABLE_LOADER";

export const SET_PARTNER_BY_ID = "SET_PARTNER_BY_ID";

export const SET_IMPORTED_USERS_LIST = "SET_IMPORTED_USERS_LIST";

export const SET_IMPORTED_USERS_LIST_TABLE_LOADER =
  "SET_IMPORTED_USERS_LIST_TABLE_LOADER";

export const SET_PARTNER_USER_BY_ID = "SET_PARTNER_USER_BY_ID";

export const SET_PARTNER_PLAN_LIST = "SET_PARTNER_PLAN_LIST";

export const SET_PARTNER_VOUCHER_LIST = "SET_PARTNER_VOUCHER_LIST";

export const SET_IS_PARTNER_VOUCHER_TABLE_LOADING =
  "SET_IS_PARTNER_VOUCHER_TABLE_LOADING";

export const SET_ALL_PARTNERS = "SET_ALL_PARTNERS";

export const SET_SELECTED_PARTNER_IDS = "SET_SELECTED_PARTNER_IDS";
