
import {
	GET_ALL_SKILLS	,
	SET_ALL_LANGUAGE,
	GET_ALL_TRANSPORTS
} from "../../actions/types/types";


const initState = {	
	skills:[]	,
	languages:[],
	transports : []
};

const SkillsReducers = (state = initState, action) => {
	switch (action.type) {				
		case GET_ALL_SKILLS:
			return { ...state, skills: action.payload.data};
		case SET_ALL_LANGUAGE:
			return { ...state, languages: action.payload.data};
		case GET_ALL_TRANSPORTS:
			return { ...state, transports:action.payload.data};
		default:
			return { ...state };
	}
};

export default SkillsReducers;
