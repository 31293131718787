import { toastr } from "react-redux-toastr";
import { api } from "../../../../api/api";
import {
  setIsSuperAdmin,
  setSubPermission,
  setUserPermissions,
} from "../../userPermissions/userPermissions";
import { setloader } from "../../loader/loaderAction";
import { Redirect } from "react-router-dom";
import { logout } from "../apiAction";

export const getUserPermissions = (userId) => {
  if (!userId) {
    return;
  }
  return async (dispatch, store) => {
    dispatch(setloader(true));
    const url = `employee/perissions?userId=${userId}`;
    const response = await api(url, {}, "get");
    if (response.status !== 200) {
      dispatch(logout());
      dispatch(setUserPermissions([]));
      return toastr.error(response.data.message);
    }
    const data = response.data.data ? response.data.data.role.permissions ? response.data.data.role.permissions : [] : [];
    dispatch(setIsSuperAdmin(response.data.data.role.isSuperAdmin));
    dispatch(setUserPermissions(data));
    dispatch(setloader(false));
  };
};

export const setSubPermissionArray = (subPermission) => {
  if (!subPermission) {
    return;
  }
  return async (dispatch, store) => {
    dispatch(setSubPermission(subPermission));
  };
};
