import { setSelectedSideMenu } from "../../sideMenuDropdown/sideMenuDropdown";

export const getSelectedSideMenu = (
  sideMenuIndex = null,
  subMenuIndex = null
) => {
  return async (dispatch, store) => {
    const data = {};

    if (sideMenuIndex !== null) {
      data.sideMenuIndex = sideMenuIndex;
    }

    if (subMenuIndex !== null) {
      data.subMenuIndex = subMenuIndex;
    }
    dispatch(setSelectedSideMenu(data));
  };
};
