export const GET_ALL_GENERAL_INQUIRIES_LIST = "GET_ALL_GENERAL_INQUIRIES_LIST";
export const SET_ALL_GENERAL_INQUIRIES_LIST = "SET_ALL_GENERAL_INQUIRIES_LIST";

export const GET_ALL_REQUESTL_INQUIRIES_LIST =
  "GET_ALL_REQUESTL_INQUIRIES_LIST";
export const SET_ALL_REQUESTL_INQUIRIES_LIST =
  "SET_ALL_REQUESTL_INQUIRIES_LIST";

export const GET_ALL_TASK_INQUIRIES_LIST = "GET_ALL_TASK_INQUIRIES_LIST";
export const SET_ALL_TASK_INQUIRIES_LIST = "SET_ALL_TASK_INQUIRIES_LIST";

export const SET_UNREAD_INUIRIES_COUNT = "SET_UNREAD_INUIRIES_COUNT"
