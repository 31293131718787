// import external modules
import React from "react";
import { NavLink } from "react-router-dom";

// Styling
import "../../../../assets/scss/components/sidebar/sidemenu/sidemenu.scss"

import SideMenu from "../sidemenuHelper";
import { connect } from "react-redux";
import { getUnreadInquiriesCount } from "../../../../redux/actions/apiAction/inquiries/inquiries";
import { getSelectedSideMenu } from "../../../../redux/actions/apiAction/sideMenuDropdown/sideMenuDropdownActions";
import { SideBarNavigation } from "../../../../navigation/SideBarNavigation";
import { ChevronRight } from "react-feather";

// Loader Image

class SideMenuContent extends React.Component {
  state = {
    defaultSelectedSideMenu: null,
    defaultSubMenuIndex: null,
  };
  getUnreadCountApiCall = async () => {
    await this.props.getUnreadInquiriesCount({})
  }

  toggleMultipleMenu = (index, subMenuIndex) => {
    if (index && typeof subMenuIndex !== "string") {
      if (this.props.selectedSideMenuState.sideMenuIndex === index) {
        return this.props.getSelectedSideMenu(null);
      }

      return this.props.getSelectedSideMenu(index, subMenuIndex);
    } else if (index) {
      if (this.props.selectedSideMenuState.sideMenuIndex === index) {
        return this.props.getSelectedSideMenu(null);
      }
      return this.props.getSelectedSideMenu(index);
    }
  };

  render() {
    if (!this.props.unreadInquiriesCount.is_set_up_once) {
      this.getUnreadCountApiCall()
    }
    // all permission Array
    const permissionsArray = this.props.permissions;
    //filter permissions side menu
    const filteredPermittedMenu = this.props.isSuperAdmin
      ? SideBarNavigation
      : SideBarNavigation.map((sideMenu, sideMenuIndex) => {
          if (sideMenu.subMenu && sideMenu.subMenu.length) {
            const subMenu = sideMenu.subMenu
              .map((subMenu, subMenuIndex) => {
                const currentBrowserPath = window.location.pathname;
                const getPermissionStatus = permissionsArray.find(
                  (permission) =>
                    permission.path === subMenu.path &&
                    permission.isAccess === true
                );

                if (!getPermissionStatus) {
                  return null;
                }
                if (
                  getPermissionStatus.path === currentBrowserPath &&
                  this.props.selectedSideMenuState.subMenuIndex !== subMenuIndex
                ) {
                  this.props.getSelectedSideMenu(sideMenu.id, subMenu.id);
                }
                return {
                  ...subMenu,
                };
              })
              .filter((item) => item !== null);
            if (!subMenu.length) {
              return null;
            }
            return { ...sideMenu, subMenu };
          } else {
            const getPermissionStatus = permissionsArray.find(
              (permission) =>
                permission.path === sideMenu.path &&
                permission.isAccess === true
            );
            if (!getPermissionStatus) {
              return null;
            }
            return {
              ...sideMenu,
            };
          }
        }).filter((item) => item !== null);

    return (
      <SideMenu
        className="sidebar-content"
        toggleSidebarMenu={this.props.toggleSidebarMenu}
      >
        {filteredPermittedMenu.length
          ? filteredPermittedMenu.map((item, index) => {
              if (item.subMenu && item.subMenu.length) {
                return (
                  <SideMenu.MenuMultiItems
                    key={item.path}
                    className="position-relative"
                    Icon={item.icon}
                    name={item.title}
                    ArrowRight={<ChevronRight />}
                    selected={
                      this.props.selectedSideMenuState.sideMenuIndex === item.id
                        ? true
                        : false
                    }
                    collapsedSidebar={false}
                    toggle={() => {
                      this.toggleMultipleMenu(item.id, crypto.randomUUID());
                    }}
                  >
                    {item.subMenu.map((subMenu, subMenuIndex) => (
                      <div
                        className={`multiple_side_menu ${
                          this.props.selectedSideMenuState.subMenuIndex ===
                          subMenu.id
                            ? "active"
                            : ""
                        }`}
                        key={subMenu.path}
                        onClick={() => {
                          this.toggleMultipleMenu(item.id, subMenu.id);
                        }}
                      >
                        <NavLink
                          to={subMenu.path}
                          activeClassName="active"
                          className=" text-white active"
                        >
                          <i className="menu-icon">{subMenu.icon}</i>
                          <span
                            style={{ fontWeight: "bold" }}
                            className="menu-item-text"
                          >
                            {subMenu.title}{" "}
                            {subMenu.title === "Inquiries" && (
                              <span>
                                ({this.props.unreadInquiriesCount.total})
                              </span>
                            )}
                          </span>
                        </NavLink>
                      </div>
                    ))}
                  </SideMenu.MenuMultiItems>
                );
              }
              return (
                <SideMenu.MenuSingleItem
                  key={item.path}
                  index={index}
                  onClick={() => {
                    this.props.getSelectedSideMenu(crypto.randomUUID());
                  }}
                >
                  <NavLink to={item.path} activeClassName="active">
                    <i className="menu-icon">{item.icon}</i>
                    <span
                      style={{ fontWeight: "bold" }}
                      className="menu-item-text"
                    >
                      {item.title}{" "}
                      {item.title === "Inquiries" && (
                        <span>({this.props.unreadInquiriesCount.total})</span>
                      )}
                    </span>
                  </NavLink>
                </SideMenu.MenuSingleItem>
              );
            })
          : new Array(20).fill().map((item, index) => {
              return (
                <div key={crypto.randomUUID()}></div>
                // <SideMenu.MenuSingleItem key={index}>
                //   <NavLink
                //     to={"/"}
                //     activeClassName={index === 0 ? "active" : ""}
                //     className="d-flex align-items-center"
                //   >
                //     <i className="menu-icon">
                //       <Menu color="#e2ac07" />
                //     </i>
                //     <img
                //       src={LoaderIcon}
                //       alt="Loader"
                //       style={{ height: "30px" }}
                //     />
                //   </NavLink>
                // </SideMenu.MenuSingleItem>
              );
            })}
        {/*
          <SideMenu.MenuSingleItem>
          <NavLink
            to=""
            activeClassName="active"
            style={{ opacity: "0", visibility: "hidden" }}
          >
            <i className="menu-icon">
              <Settings style={{ color: "#e2ac07" }} size={18} />
            </i>
            <span style={{ fontWeight: "bold" }} className="menu-item-text">
              1112
            </span>
          </NavLink>
        </SideMenu.MenuSingleItem>
      */}
      </SideMenu>
    )
  }
}

// export default SideMenuContent;

const mapStateToProps = ({
  inquiries,
  login,
  userPermissions,
  selectedSideMenu,
}) => {
  const { unreadInquiriesCount } = inquiries;
  const loginUser = login.loginUser;
  const permissions = userPermissions.userPermissions;
  const isSuperAdmin = userPermissions.isSuperAdmin;
  const selectedSideMenuState = selectedSideMenu.selectedSideMenu;
  return {
    unreadInquiriesCount,
    loginUser,
    permissions,
    isSuperAdmin,
    selectedSideMenuState,
  };
};

const mapDispatchToProps = {
  getUnreadInquiriesCount,
  getSelectedSideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuContent)
