import { Route_Ignore_Permission_Conditions } from "../constants/constants";

export const verifiedRoutePermissions = (
  permissionArray,
  path,
  isSuperAdmin = false
) => {
  const nestedRoute = ["/user/:id"];
  const testNestedRoute = nestedRoute.includes(path);

  if (!permissionArray.length) {
    return false;
  }
  if (Route_Ignore_Permission_Conditions.includes(path)) {
    return true;
  }

  if (testNestedRoute) {
    if (isSuperAdmin) {
      const findPermission = permissionArray.find((item) => item.path === path);
      if (findPermission) {
        let subPermission = {};
        for (const key in findPermission.subPermission) {
          subPermission[key] = true;
        }
        findPermission.subPermission = subPermission;
      }

      return findPermission;
    }
    return permissionArray.find(
      (item) => item.path === path && item.isAccess === true
    );
  } else {
    if (isSuperAdmin) {
      const findPermission = permissionArray.find((item) =>
        path.includes(item.path)
      );
      if (findPermission) {
        let subPermission = {};
        for (const key in findPermission.subPermission) {
          subPermission[key] = true;
        }
        findPermission.subPermission = subPermission;
      }
      return findPermission;
    }
    return permissionArray.find(
      (item) => path.includes(item.path) && item.isAccess === true
    );
  }
};

export const verifiedSubPermission = (
  subPermissions,
  permissionKey = "",
  isSuperAdmin = false
) => {
  if (isSuperAdmin) {
    return true;
  }
  if (!permissionKey) {
    return false;
  }
  return subPermissions && subPermissions[permissionKey] ? true : false;
};

export const timeFromDate = (timeString, addedMinutes = 0) => {
  if (timeString === "") {
    return;
  }
  const timeArray = [
    ...timeString.split(" ")[0].split(":"),
    timeString.split(" ")[1],
  ];
  const newTime = new Date("2020-08-12");
  if (
    timeArray[3] &&
    timeArray[3].toLowerCase() === "pm" &&
    Number(timeArray[0]) !== 12
  ) {
    newTime.setHours(Number(timeArray[0]) + 12);
  } else if (
    timeArray[3] &&
    timeArray[3].toLowerCase() === "am" &&
    Number(timeArray[0]) === 12
  ) {
    newTime.setHours(0);
  } else {
    newTime.setHours(Number(timeArray[0]));
  }
  newTime.setMinutes(Number(timeArray[1]) + addedMinutes);
  newTime.setSeconds(Number(timeArray[2]));

  return newTime;
};

// get video duration for video file
export const getVideoLength = async (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      return reject("File not found");
    }
    if (!file.type.includes("video")) {
      return reject("file is not valid");
    }
    const video = document.createElement("video");
    // Add an event listener to the video element to get the duration once it's loaded
    video.addEventListener("loadedmetadata", function() {
      const durationInMilliSecond = Math.floor(video.duration * 1000);
      return resolve(Number(durationInMilliSecond));
    });
    // Set the video source to the selected file
    video.src = URL.createObjectURL(file);
    // Load the video
    video.load();
  });
};
