// theme color
export const Theme_COLOR = "#e2ac07";
// global constants
export const FEE_KEY = "offerCharge";
// GOOGLE_MAP_API_KEY
export const GOOGLE_MAP_API_KEY = "AIzaSyALzHTikqUmrNz9CzG_eIQskX9FKJ2Oht0";
// local ----------------------------------------------------------------
//export const API_URL = "http://localhost:1337/api/";
// Develop ----------------------------------------------------------------
// export const API_URL = "https://test.co-tasker.com/backend/api/";
//Staging ----------------------------------------------------------------
// export const API_URL = "https://api.staging.co-tasker.com/api/";
//Live ----------------------------------------------------------------
export const API_URL = "https://api.co-tasker.com/api/";
