import {   SET_FORGETPASSWORD_FLAG,
             SET_FORGETPASSWORD } from '../types/types';


export const setForgetPassword = (value) =>{
    
    return {
        
        type:SET_FORGETPASSWORD,
        payload:{data:value}
    }
   
}

export const setForgetPasswordFlag = (value) =>{
    return{
        type:SET_FORGETPASSWORD_FLAG,
        payload:{data:value}
    }
}