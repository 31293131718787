import {     
    CURRENT_TRACKING_DETAILS
} from '../types/types'


export const setCurrentTrackingData = (value) =>{    
    return{
        type:CURRENT_TRACKING_DETAILS,
        payload:{data:value}
    }
}

