import React from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import MainLayout from "../layouts/mainLayout";
import { verifiedRoutePermissions } from "../utility/utils";
import { setSubPermissionArray } from "../redux/actions/apiAction/userPermissions/userPermissionsActions";

// Main Layout
const PrivateRoute = ({
  isAuthenticated,
  userPermissions,
  isSuperAdmin,
  component: Component,
  ...rest
}) => {
  const dispatch = useDispatch();
  if (userPermissions && userPermissions.length) {
    const defaultRoute = userPermissions.find(
      (item) => item.isAccess && !item.path.includes("/:id")
    );
    const routePermissions = verifiedRoutePermissions(
      userPermissions,
      rest.path,
      isSuperAdmin
    );

    if (!isSuperAdmin && !routePermissions) {
      return <Redirect to={defaultRoute.path} />;
    }
    if (!isSuperAdmin) {
      dispatch(
        setSubPermissionArray(
          (routePermissions && routePermissions.subPermission) || {}
        )
      );
    } else {
      let subPermission = {};
      if (routePermissions) {
        for (const key in routePermissions.subPermission) {
          subPermission[key] = true;
        }
      }
      dispatch(setSubPermissionArray(subPermission));
    }
  }
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  const isAuthenticated = state.login.loginFlag;
  const userPermissions = state.userPermissions.userPermissions;
  const isSuperAdmin = state.userPermissions.isSuperAdmin;
  return { isAuthenticated, userPermissions, isSuperAdmin };
};

export default connect(mapStateToProps)(PrivateRoute);
