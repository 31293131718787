import { SET_ALL_ROLES } from "../../actions/types/employees/employeeTypes";
import {
  SET_ROLE_GET_BY_ID,
  SET_ROLE_LIST,
  SET_ROLE_TABLE_LOADING,
} from "../../actions/types/roles";

const initialState = {
  roleList: [],
  isRoleTableLoading: true,
  roleGetById: {},
  allRoles: [],
};

const Roles = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLE_LIST:
      return { ...state, roleList: action.payload.data };
    case SET_ROLE_TABLE_LOADING:
      return { ...state, isRoleTableLoading: action.payload.data };
    case SET_ROLE_GET_BY_ID:
      return { ...state, roleGetById: action.payload.data };
    case SET_ALL_ROLES:
      return { ...state, allRoles: action.payload.data };

    default:
      return state;
  }
};

export default Roles;
