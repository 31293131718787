export const SET_LOADER = "SET_LOADER";
export const SET_SIDEBAR_COLLAPSED = "SET_SIDEBAR_COLLAPSED";
export const DASHBOARD_SIDEBAR_TOGGLE = "DASHBOARD_SIDEBAR_TOGGLE";
// Event Action Types
export const ADD_EVENT = "ADD_EVENT";

/**
 * Login
 */
export const SET_LOGIN_USER = "SET_LOGIN_USER";
export const SET_LOGIN_FLAG = "SET_LOGIN_FLAG";
export const SET_LOGIN_ERROR_FLAG = "SET_LOGIN_ERROR_FLAG";
export const SET_ALL_USER_DATA = "SET_ALL_USER_DATA";

/**
 *   Dashboard Action
 */
export const SET_DASHBOARD_CARD_DATA = "SET_DASHBOARD_CARD_DATA";

/**
 *  category
 */

export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_ALL_DOCUMENT = "GET_ALL_DOCUMENT";
export const SET_C_PC_TYPE_CATEGORY = "SET_C_PC_TYPE_CATEGORY";
/**
 * user
 */
export const GET_ALL_USER = "GET_ALL_USER";
export const CURREN_USER_DATA = "CURREN_USER_DATA";
export const SET_SKILLS_USER_DATA = "SET_SKILLS_USER_DATA";
export const SET_MULTI_SKILLS_USER_DATA = "SET_MULTI_SKILLS_USER_DATA";
export const SET_MULTI_TRANSPORT_USER_DATA = "SET_MULTI_TRANSPORT_USER_DATA";
export const SET_TRANSPORT_USER_DATA = "SET_TRANSPORT_USER_DATA";
/**
 * forgetPssword
 */

export const SET_FORGETPASSWORD = "SET_FORGETPASSWORD";
export const SET_FORGETPASSWORD_FLAG = " SET_FORGETPASSWORD_FLAG ";
/**
 * Set Password
 */

export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";

/**
 * Reset Password
 */

export const RESET_PASSWORD = "RESET_PASSWORD";

/**
 * Get Skills
 */

export const GET_ALL_SKILLS = "GET_ALL_SKILLS";
export const SET_ALL_LANGUAGE = "SET_ALL_LANGUAGE";
export const GET_ALL_TRANSPORTS = "GET_ALL_TRANSPORTS";
/**
 * get SearchBAr Data
 */

export const GET_ALL_FILTEREDDATA = "GET_ALL_FILTEREDDATA";

/**
 * Task
 */

export const CURRENT_TASK_DETAILS = "CURRENT_TASK_DETAILS";

export const SET_ALL_MARKER_IMAGE = "SET_ALL_MARKER_IMAGE";

export const SET_ALL_SETTINGS = "SET_ALL_SETTINGS";

export const SET_ALL_VOUCHERS = "SET_ALL_VOUCHERS";

export const SET_ALL_TRANSACTION = "SET_ALL_TRANSACTION";

export const SET_COMMENT_OF_TASK = "SET_COMMENT_OF_TASK";

export const SET_USER_PAGE_HISTORY = "SET_USER_PAGE_HISTORY";
export const SET_TASK_PAGE_HISTORY = "SET_TASK_PAGE_HISTORY";
export const SET_INVOICE_PAGE_HISTORY = "SET_INVOICE_PAGE_HISTORY";
export const GET_ALL_UNASSIGNEDVOUCHERS = "GET_ALL_UNASSIGNEDVOUCHERS";
export const GET_ALL_PARTNER_VOUCHERS = "GET_ALL_PARTNER_VOUCHERS";
export const CURRENT_VOUCHER_DETAILS = "CURRENT_VOUCHER_DETAILS";
export const CURRENT_TRACKING_DETAILS = "CURRENT_TRACKING_DETAILS";
export const SET_CURRENT_OFFER_DATA = "SET_CURRENT_OFFER_DATA";
export const SET_CURRENT_PORTFOLIO_DATA = "SET_CURRENT_PORTFOLIO_DATA";
export const SET_CURRENT_CERTIFICATE_DATA = "SET_CURRENT_CERTIFICATE_DATA";
export const SET_ALL_USERS_CITY = "SET_ALL_USERS_CITY";
export const SET_ALL_TASKS_CITY = "SET_ALL_TASKS_CITY";
