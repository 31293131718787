export const SET_PLANS_LIST = "SET_PLANS_LIST";

export const SET_PLAN_GET_BY_ID = "SET_PLAN_GET_BY_ID";

export const SET_IS_PLANS_LIST_TABLE_LOADING =
  "SET_IS_PLANS_LIST_TABLE_LOADING";

export const SET_PLAN_CATEGORIES_BY_ID = "SET_PLAN_CATEGORIES_BY_ID";

export const SET_PLAN_SELECTED_CATEGORIES = "SET_PLAN_SELECTED_CATEGORIES";
