import React from 'react';
import { ClipLoader } from 'react-spinners';
import { connect } from 'react-redux';

class LoaderComponent extends React.Component {

	
   
	render() {
		
		
			if(this.props.loader){
				return (
					<div className='sweet-loading main-loader'>
						<div className='loaderBackground'>
							<ClipLoader
								className="clip-loader loaderBackground"
								sizeUnit={"px"}
								size={60}
								color={'#FF586B'}
								loading={this.props.loader}
							/>
						</div>
					
					</div> 
				)
			}else{
				return null
			}
			
		
	}
}

// export default LoaderComponent;

const mapStateToProps = (state) => {  
    const {loader} = state.loader
    // console.log({loader})
    // const filter = state.filteredData
    return {  loader}
}

export default connect(mapStateToProps, {})(LoaderComponent);