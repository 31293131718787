import { SET_ALL_SETTINGS } from '../../actions/types/types'


const initialState={
    
   settings:[]
}


const SettingReducers=(state=initialState,action)=>{
    switch (action.type){
        
        case SET_ALL_SETTINGS:
            return{...state,settings:action.payload.data}
        default:
            return state;
    }
}

export default SettingReducers;