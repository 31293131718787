import {
  SET_EMPLOYEE_GET_BY_ID,
  SET_EMPLOYEE_LIST,
  SET_IS_EMPLOYEE_TABLE_LOADING,
} from "../../actions/types/employees/employeeTypes";

const initialState = {
  employeeList: [],
  isEmployeeTableLoading: true,
  employeeGetById: {},
};

const Employees = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYEE_LIST:
      return { ...state, employeeList: action.payload.data };
    case SET_IS_EMPLOYEE_TABLE_LOADING:
      return { ...state, isEmployeeTableLoading: action.payload.data };
    case SET_EMPLOYEE_GET_BY_ID:
      return { ...state, employeeGetById: action.payload.data };

    default:
      return state;
  }
};

export default Employees;
