import {
  SET_IS_SUPER_ADMIN,
  SET_SUB_PERMISSIONS,
  SET_USER_PERMISSIONS,
} from "../../actions/types/userPermissions/userPermissionsTypes";

const initialState = {
  userPermissions: [],
  subPermissions: {},
  isSuperAdmin: false,
};

const UserPermission = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PERMISSIONS:
      return { ...state, userPermissions: action.payload.data };
    case SET_SUB_PERMISSIONS:
      return { ...state, subPermissions: action.payload.data };
    case SET_IS_SUPER_ADMIN:
      return { ...state, isSuperAdmin: action.payload.data };
    default:
      return state;
  }
};

export default UserPermission;
