import {
  SET_IS_SUPER_ADMIN,
  SET_SUB_PERMISSIONS,
  SET_USER_PERMISSIONS,
} from "../types/userPermissions/userPermissionsTypes";

export const setUserPermissions = (value) => {
  return {
    type: SET_USER_PERMISSIONS,
    payload: { data: value },
  };
};

export const setSubPermission = (value) => {
  return {
    type: SET_SUB_PERMISSIONS,
    payload: { data: value },
  };
};

export const setIsSuperAdmin = (value) => {
  return {
    type: SET_IS_SUPER_ADMIN,
    payload: { data: value },
  };
};
