import {
  GET_ALL_CATEGORY,
  GET_ALL_DOCUMENT,
  SET_C_PC_TYPE_CATEGORY,
} from "../types/types";

export const setCategoryData = (value) => {
  return {
    type: GET_ALL_CATEGORY,
    payload: { data: value },
  };
};

export const setDocumentData = (value) => {
  return {
    type: GET_ALL_DOCUMENT,
    payload: { data: value },
  };
};
// C and PC type
export const setALlCategory = (value) => {
  return {
    type: SET_C_PC_TYPE_CATEGORY,
    payload: { data: value },
  };
};
