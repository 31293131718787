import {
  setGeneralInquiriesList,
  setRequestInquiriesList,
  setTaskInquiriesList,
  setUnreadInquiriesCount,
} from "../../inquiries/inquiries";
import { api } from "../../../../api/api";
import { history } from "../../../../app/AppRouter";
import { toastr } from "react-redux-toastr";
import { setloader } from "../../loader/loaderAction";

//general inquiries
export const getGeneralInquiriesList = (payload) => {
  return async (dispatch, store) => {
    let url = `inquiries?type=G&pageLimit=${payload.pageSize}&pageNo=${payload.page}`;

    if ([true, false, "true", "false", ""].includes(payload.replied)) {
      if (payload.replied !== "") {
        url += `&isReplied=${payload.replied}`;
      }
    } else {
      url += `&isReplied=${false}`;
    }
    await api(url, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(
            setGeneralInquiriesList({
              ...res.data.data,
              pageSize: payload.pageSize,
              page: payload.page,
              replied: payload.replied,
            })
          );
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};
//Request inquiries
export const getRequestInquiriesList = (payload) => {
  return async (dispatch, store) => {
    let url = `callinquiries?pageLimit=${payload.pageSize}&pageNo=${payload.page}`;

    if ([true, false, "true", "false", ""].includes(payload.replied)) {
      if (payload.replied !== "") {
        url += `&isReplied=${payload.replied}`;
      }
    } else {
      url += `&isReplied=${false}`;
    }
    await api(url, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(
            setRequestInquiriesList({
              ...res.data.data,
              pageSize: payload.pageSize,
              page: payload.page,
              replied: payload.replied,
            })
          );
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};
//Task inquiries
export const getTaskInquiriesList = (payload) => {
  return async (dispatch, store) => {
    let url = `inquiries?type=T&pageLimit=${payload.pageSize}&pageNo=${payload.page}`;

    if ([true, false, "true", "false", ""].includes(payload.replied)) {
      if (payload.replied !== "") {
        url += `&isReplied=${payload.replied}`;
      }
    } else {
      url += `&isReplied=${false}`;
    }
    await api(url, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          dispatch(
            setTaskInquiriesList({
              ...res.data.data,
              pageSize: payload.pageSize,
              page: payload.page,
              replied: payload.replied,
            })
          );
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const getUnreadInquiriesCount = (payload) => {
  return async (dispatch, store) => {
    let url = `V11/unreadInquires`;
    await api(url, "", "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error(res.data.error);
        } else {
          let dataToDispatch = {
            total: res.data.data.Total,
            general: res.data.data.countG,
            task: res.data.data.countT,
            call_count: res.data.data.callCount,
            is_set_up_once: true,
          };

          dispatch(setUnreadInquiriesCount(dataToDispatch));
        }

        //
        dispatch(setloader(false));
      })
      .catch((err) => {
        toastr.error("You are not able to login");
      });
  };
};

export const deleteGeneralInquiries = (value) => {
  return async (dispatch, store) => {
    await api(`deleteInquiry/${value.id}`, value, "delete")
      .then((res) => {
        if (res.status !== 200) {
          toastr.error(res.data.error);
        } else {
          toastr.success("Mark As Replied Successfully");
        }
      })
      .catch((err) => {
        toastr.error("Error");
      });
  };
};
export const deleteRequestInquiries = (value) => {
  return async (dispatch, store) => {
    await api(`deleteCallInquiry/${value.id}`, value, "delete")
      .then((res) => {
        if (res.status !== 200) {
          toastr.error(res.data.error);
        } else {
          toastr.success("Mark As Replied Successfully");
        }
      })
      .catch((err) => {
        toastr.error("Error");
      });
  };
};
export const deleteTaskInquiries = (value) => {
  return async (dispatch, store) => {
    await api(`deleteInquiry/${value.id}`, value, "delete")
      .then((res) => {
        if (res.status !== 200) {
          toastr.error(res.data.error);
        } else {
          toastr.success("Mark As Replied Successfully");
        }
      })
      .catch((err) => {
        toastr.error("Error");
      });
  };
};
