import {
  SET_ALL_PARTNER_LIST,
  SET_ALL_PARTNERS,
  SET_IMPORTED_USERS_LIST,
  SET_IMPORTED_USERS_LIST_TABLE_LOADER,
  SET_IS_PARTNER_VOUCHER_TABLE_LOADING,
  SET_PARTNER_BY_ID,
  SET_PARTNER_PLAN_LIST,
  SET_PARTNER_TABLE_LOADER,
  SET_PARTNER_USER_BY_ID,
  SET_PARTNER_VOUCHER_LIST,
  SET_SELECTED_PARTNER_IDS,
} from "../../actions/types/partner/partner";

const initialState = {
  allPartnerList: [],
  partnerTableLoader: true,
  partnerById: {},
  importedUsersList: [],
  importedUsersListTableLoader: true,
  partnerUserById: {},
  partnerPlanList: [],
  partnerVoucherList: {},
  isPartnerVoucherTableLoading: false,
  allPartners: [],
  selectedPartnerIds: [],
};

const Partners = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_PARTNER_LIST:
      return { ...state, allPartnerList: action.payload.data };
    case SET_PARTNER_TABLE_LOADER:
      return { ...state, partnerTableLoader: action.payload.data };
    case SET_PARTNER_BY_ID:
      return { ...state, partnerById: action.payload.data };
    case SET_IMPORTED_USERS_LIST:
      return { ...state, importedUsersList: action.payload.data };
    case SET_IMPORTED_USERS_LIST_TABLE_LOADER:
      return { ...state, importedUsersListTableLoader: action.payload.data };
    case SET_PARTNER_USER_BY_ID:
      return { ...state, partnerUserById: action.payload.data };
    case SET_PARTNER_PLAN_LIST:
      return { ...state, partnerPlanList: action.payload.data };
    case SET_PARTNER_VOUCHER_LIST:
      return { ...state, partnerVoucherList: action.payload.data };
    case SET_IS_PARTNER_VOUCHER_TABLE_LOADING:
      return { ...state, isPartnerVoucherTableLoading: action.payload.data };
    case SET_ALL_PARTNERS:
      return { ...state, allPartners: action.payload.data };
    case SET_SELECTED_PARTNER_IDS:
      return { ...state, selectedPartnerIds: action.payload.data };

    default:
      return state;
  }
};

export default Partners;
