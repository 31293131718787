import React from "react";
import { Link } from "react-router-dom";
import { Form,  Collapse, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";

import {  Menu,LogOut,Key} from "react-feather";
import { connect } from 'react-redux'


import { logout } from "../../../redux/actions/apiAction/apiAction";

import userImage from "../../../assets/img/portrait/small/avatar-s-1.png";


class ThemeNavbar extends React.Component {
   handleClick = e => {
      this.props.toggleSidebarMenu("open");
      document.getElementsByTagName('body')[0].classList.add(`body-hidden`);
   };
   constructor(props) {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.state = {
         isOpen: false
      };
   }
   toggle() {
      this.setState({
         isOpen: !this.state.isOpen
      });
   }

   render() {       
      return (
         <Navbar className="navbar navbar-expand-lg navbar-light bg-faded">
            <div className="container-fluid px-0">
               <div className="navbar-header">
                  <Menu size={14} className="navbar-toggle d-lg-none float-left" onClick={this.handleClick.bind(this)} data-toggle="collapse"/>
                  <Form className="navbar-form mt-1 float-left" role="search">                     
                  </Form>                  
                  
               </div>
               <div className="navbar-container">
                  <Collapse isOpen={this.state.isOpen} navbar>
                     <Nav className="ml-auto float-right" navbar>
                        <UncontrolledDropdown nav inNavbar className="pr-1">                           
                        </UncontrolledDropdown>

                        <UncontrolledDropdown nav inNavbar className="pr-1">
                           <DropdownToggle nav>                              
                              <img src={userImage} alt="logged-in-user" className="rounded-circle width-35" />
                             
                           </DropdownToggle>
                           <DropdownMenu right>
                               <DropdownItem>
                                 <span className="font-small-3">                                    
                                    {this.props.login.firstname}
                                 </span>
                              </DropdownItem> 
                              <DropdownItem divider />                              
                              <Link to="/reset-password" className="p-0">
                                 <DropdownItem>
                                    <Key onClick={this.props.resetPassword} size={16} className="mr-1" /> Reset Password
                                 </DropdownItem>
                              </Link>
                              
                              <DropdownItem divider />                              
                              <DropdownItem onClick={this.props.logout}>
                                 <LogOut  size={16} className="mr-1" /> Logout
                              </DropdownItem>
                           </DropdownMenu>
                        </UncontrolledDropdown>
                     </Nav>
                  </Collapse>
               </div>
            </div>
         </Navbar>
      );
   }
}
const mapStateToProps = (state) => {
   
   const { user } = state.user
   const filter = state.filteredData
   const  login  = state.login.loginUser;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
   return { filter, user, login }
}


export default connect (mapStateToProps, { logout })(ThemeNavbar);
