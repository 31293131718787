import {SET_NEW_PASSWORD} from '../types/types';


export const setNewPassword =(value)=>{
    
      return{
          
          type:SET_NEW_PASSWORD,
          payload : {data:value}
      }
}