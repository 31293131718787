import { SET_NEW_PASSWORD } from '../../actions/types/types';


const initialState={
    newPassword:{}
}

const SetNewPasword =(state=initialState, action ) =>{
    switch(action.type){
        case SET_NEW_PASSWORD:
            return { ...state , newPassword : action.payload.data }
            default :
            return state;
    }
}

export default SetNewPasword;