// import external modules
import React, { lazy } from "react";
import { Router, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import "react-perfect-scrollbar/dist/css/styles.css";
import { connect } from "react-redux";
import { getAllCategoryData } from "../redux/actions/apiAction/apiAction";
import { getUserPermissions } from "../redux/actions/apiAction/userPermissions/userPermissionsActions";
import { setloader } from "../redux/actions/loader/loaderAction";

const createBrowserHistory = require("history").createBrowserHistory;

// const LazyLogin = lazy(() => import("../views/pages/login"));
const LazyLogin = lazy(() => import("../views/pages/login"));

// Main Layout

const LazyDashboard = lazy(() => import("../views/dashboard/dashboard"));

const LazyForgetPassword = lazy(() => import("../views/pages/forgotPassword"));

const LazySetPassword = lazy(() => import("../views/pages/setPassword"));
const LazyReSetPassword = lazy(() => import("../views/pages/resetPassword"));

const LazyCategory = lazy(() => import("../views/category/category"));
// const LazySkills = lazy(() => import ("../views/skills/skills")));
const LazySkillUserListing = lazy(() =>
  import("../views/skills/skilluserlist")
);
const LazySkillsUserListing = lazy(() =>
  import("../views/skills/skillsuserlist")
);
const LazyTransportUserListing = lazy(() =>
  import("../views/transports/transportuserlist")
);
const LazyTransportsUserListing = lazy(() =>
  import("../views/transports/transportuserslist")
);

// Error Pages
// const LazyErrorPage = lazy(() => import("../views/pages/error")));
const LazyUser = lazy(() => import("../views/user/user"));
const LazyTask = lazy(() => import("../views/task/task"));
const LazyUserDetails = lazy(() => import("../views/user/userdetails"));
const LazyTaskDetails = lazy(() => import("../views/task/taskdetails"));

const LazyMarkerImages = lazy(() => import("../views/markerImage/markerImage"));
const LazySettings = lazy(() => import("../views/Settings/settings"));
const LazyVouchers = lazy(() => import("../views/Vouchers/Vouchers"));
const LazyVoucherDetails = lazy(() =>
  import("../views/Vouchers/VoucherDetails")
);

const LazyTracking = lazy(() => import("../views/Tracking/Tracking"));
const LazyTrackingDetails = lazy(() =>
  import("../views/Tracking/TrackingDetails")
);
const LazyOfferDetails = lazy(() => import("../views/task/offerdetails"));

const LazyTransaction = lazy(() => import("../views/transaction/transaction"));
const LazyTransactionDetails = lazy(() =>
  import("../views/transaction/viewTransaction")
);

const LazyRequests = lazy(() => import("../views/pages/requests"));
const LazyUserInvoice = lazy(() => import("../views/invoice/invoice"));
const LazyCertificate = lazy(() => import("../views/user/certificate"));
const LazyPortFolio = lazy(() => import("../views/user/portfolio"));

const LazyReasons = lazy(() => import("../views/reasons/reasonsList"));

const LazyPromotionalNotification = lazy(() =>
  import("../views/notification/promotionalNotification")
);
const LazyPortfolies = lazy(() =>
  import("../views/pages/skillTransportWrapper")
);
const LazyActivities = lazy(() => import("../views/activities/Activities"));
const LazyChatInfo = lazy(() => import("../views/chat-info/ChatInfo"));
const LazyChatMessages = lazy(() => import("../views/chat-info/MessageList"));

const LazyCancellationRequestDetail = lazy(() =>
  import("../views/task/taskCancellationRequestDetail")
);
const LazyInquiries = lazy(() => import("../views/inquiries/inquiries-list"));

// lazy Content
const LazyContent = lazy(() => import("../views/content/Content"));

// Lazy Partners
const LazyPartners = lazy(() => import("../views/partners/Partners"));
// Lazy Add new partner
const LazyAddNewPartner = lazy(() =>
  import("../views/partners/add/AddNewPartner")
);

// lazy Partner user details
const LazyPartnerUserDetails = lazy(() =>
  import("../views/partners/users/[id]/PartnerUserDetails")
);

// Lazy Partner Details
const LazyPartnersDetails = lazy(() =>
  import("../views/partners/[id]/PartnerDetails")
);

// Lazy Plans list
const LazyPlans = lazy(() => import("../views/plans/Plans"));
// Lazy Add New Plan
const LazyAddNewPlan = lazy(() => import("../views/plans/add/AddNewPlan"));
// Lazy Plan Details
const LazyPlanDetails = lazy(() => import("../views/plans/[id]/PlanDetails"));

// Lazy Employees
const LazyEmployees = lazy(() => import("../views/employees/Employees"));
const LazyAddEmployee = lazy(() =>
  import("../views/employees/add/AddEmployee")
);
const LazyEmployeeDetails = lazy(() =>
  import("../views/employees/[id]/EmployeeDetails")
);

// Lazy Roles
const LazyRoles = lazy(() => import("../views/roles/Roles"));
const LazyAddRole = lazy(() => import("../views/roles/add/AddRole"));
const LazyRoleDetails = lazy(() => import("../views/roles/[id]/RoleDetails"));

// Lazy Video
const LazyVideoSetting = lazy(() =>
  import("../views/videoSetting/VideoSetting")
);

const AiAssisstant = lazy(() => import("../views/ai-assisstant"));

//share with friends
// const LazyShare = lazy(() =>import ('../views/share/Share'));
// const LazyUserVoucherDetails=lazy(()=>import ('../views/share/userVoucherDetails'));
// const LazyAddUserModal=lazy(()=>import('../views/share/AddUserModal'))
const LazyKycDetails = lazy(() =>
  import("../views/requestKyc/RequestKycDetails")
);

// set base route name
export const history = createBrowserHistory({ basename: "" });

class AppRouter extends React.Component {
  componentWillMount = async () => {
    if (this.props.category.length <= 0) {
      await this.props.getAllCategoryData();
    }
    if (this.props && this.props.loginUser.id) {
      await this.props.getUserPermissions(this.props.loginUser.id);
    }
  };

  componentDidMount = async () => {
    if (this.props && this.props.loginUser.id) {
      await this.props.getUserPermissions(this.props.loginUser.id);
    }
  };

  render() {
    return (
      <Router history={history}>
        <div>
          <Switch>
            {/* public route for login , forgot password */}
            <PublicRoute path="/login" component={LazyLogin} exact={true} />
            <PublicRoute
              path="/forget-password"
              component={LazyForgetPassword}
              exact={true}
            />

            <PublicRoute
              path="/set-password"
              component={LazySetPassword}
              exact
            />
            <PrivateRoute path="/dashboard" component={LazyDashboard} />

            <PrivateRoute
              path="/reset-password"
              component={LazyReSetPassword}
              exact={true}
            />

            {/* Partner */}
            <PrivateRoute path="/partners" component={LazyPartners} exact />
            <PrivateRoute path="/partners/add" component={LazyAddNewPartner} />
            <PrivateRoute
              path="/partners/users/:id"
              component={LazyPartnerUserDetails}
            />
            <PrivateRoute
              path="/partners/:id"
              component={LazyPartnersDetails}
            />

            {/* Plans */}
            <PrivateRoute path="/plans" component={LazyPlans} exact />
            <PrivateRoute path="/plans/add" component={LazyAddNewPlan} />
            <PrivateRoute path="/plans/:id" component={LazyPlanDetails} />

            {/* Content */}
            <PrivateRoute path="/content" component={LazyContent} exact />

            {/* employees */}
            <PrivateRoute path="/employees" component={LazyEmployees} exact />
            <PrivateRoute
              path="/employees/add"
              component={LazyAddEmployee}
              exact
            />
            <PrivateRoute
              path="/employees/:id"
              component={LazyEmployeeDetails}
              exact
            />

            {/* Roles */}
            <PrivateRoute path="/roles" component={LazyRoles} exact />
            <PrivateRoute path="/roles/add" component={LazyAddRole} exact />
            <PrivateRoute path="/roles/:id" component={LazyRoleDetails} exact />

            <PrivateRoute path="/category" component={LazyCategory} exact />
            <PrivateRoute path="/portfolios" component={LazyPortfolies} exact />

            {/* <PrivateRoute path="/skills" component={LazySkills} exact /> */}
            <PrivateRoute
              path="/skills/:skillArray"
              component={LazySkillsUserListing}
              exact
            />
            <PrivateRoute path="/skill/:id" component={LazySkillUserListing} />
            <PrivateRoute
              path="/transports/:transportArray"
              component={LazyTransportsUserListing}
              exact
            />
            <PrivateRoute
              path="/transport/:id"
              component={LazyTransportUserListing}
            />
            <PrivateRoute path="/user" component={LazyUser} exact={true} />
            <PrivateRoute path="/user/:id" component={LazyUserDetails} />
            <PrivateRoute
              path="/requests"
              component={LazyRequests}
              exact={true}
            />
            <PrivateRoute
              path="/reasons"
              component={LazyReasons}
              exact={true}
            />

            <PrivateRoute path="/task" component={LazyTask} />

            <PrivateRoute path="/tasked/:id" component={LazyTaskDetails} />
            <PrivateRoute path="/offer/:id" component={LazyOfferDetails} />

            <PrivateRoute path="/markerImage" component={LazyMarkerImages} />
            <PrivateRoute path="/settings" component={LazySettings} />

            {/* VIDEO SETTING */}
            <PrivateRoute path="/videoSettings" component={LazyVideoSetting} />

            <PrivateRoute path="/vouchers" component={LazyVouchers} />
            <PrivateRoute path="/voucher/:id" component={LazyVoucherDetails} />

            <PrivateRoute path="/tracking" exact component={LazyTracking} />
            <PrivateRoute
              path="/tracking/:id"
              component={LazyTrackingDetails}
            />

            <PrivateRoute
              path="/transaction"
              exact
              component={LazyTransaction}
            />
            <PrivateRoute
              path="/transaction/:id"
              component={LazyTransactionDetails}
            />

            <PrivateRoute path="/invoice" component={LazyUserInvoice} />

            <PrivateRoute
              path="/notification"
              component={LazyPromotionalNotification}
            />
            <PrivateRoute path="/certificate/:id" component={LazyCertificate} />
            <PrivateRoute path="/portfolio/:id" component={LazyPortFolio} />

            <PrivateRoute
              path="/chat-info"
              component={LazyChatInfo}
              exact={true}
            />
            <PrivateRoute
              path="/chat-session/:id"
              component={LazyChatMessages}
              exact={true}
            />
            <PrivateRoute path="/activities" component={LazyActivities} />

            <PrivateRoute path="/inquiries" component={LazyInquiries} />

            <PrivateRoute
              path="/cancellation-request/:id"
              component={LazyCancellationRequestDetail}
            />

            <PrivateRoute path="/ai-assistance" component={AiAssisstant} />
            {/* <PrivateRoute path='/share' component={LazyShare} />

            <PrivateRoute path='/userVoucherDetails/:id' component={LazyUserVoucherDetails} />
            <PrivateRoute path='/addUser' component={LazyAddUserModal} /> */}

            <PrivateRoute path="/kycDetails/:id" component={LazyKycDetails} />

            {/* <PublicRoute path="*" component={LazyPAGENotFound404} />                        */}
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  const isAuthenticated = state.login.loginFlag;
  const login_route = state.login.login_route;
  const loginUser = state.login.loginUser;
  const permissions = state.userPermissions.userPermissions;
  const { category } = state.category;
  return { isAuthenticated, login_route, category, loginUser, permissions };
};

export default connect(mapStateToProps, {
  getAllCategoryData,
  getUserPermissions,
  setloader,
})(AppRouter);
