import {
  GET_ALL_UNASSIGNEDVOUCHERS,
  CURRENT_VOUCHER_DETAILS,
  GET_ALL_PARTNER_VOUCHERS,
} from "../types/types";

export const getAllUnAssignedVouchers = (value) => {
  return {
    type: GET_ALL_UNASSIGNEDVOUCHERS,
    payload: { data: value },
  };
};

export const setAllPartnerVouchers = (value) => {
  return {
    type: GET_ALL_PARTNER_VOUCHERS,
    payload: { data: value },
  };
};

export const setCurrentVoucherData = (value) => {
  return {
    type: CURRENT_VOUCHER_DETAILS,
    payload: { data: value },
  };
};
