import {
  SET_ALL_GENERAL_INQUIRIES_LIST,
  SET_ALL_REQUESTL_INQUIRIES_LIST,
  SET_ALL_TASK_INQUIRIES_LIST,
  SET_UNREAD_INUIRIES_COUNT,
} from "../../actions/types/inquiries/inquiries";

const initialState = {
  generalInquiriesList: [],
  requestInquiriesList: [],
  taskInquiriesList: [],
  unreadInquiriesCount : {
    total : 0,
    general : 0,
    task : 0,
    call_count: 0,
    is_set_up_once : false
  }
};

const Inquiries = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_GENERAL_INQUIRIES_LIST:
      return { ...state, generalInquiriesList: action.payload.data };
    case SET_ALL_REQUESTL_INQUIRIES_LIST:
      return { ...state, requestInquiriesList: action.payload.data };
    case SET_ALL_TASK_INQUIRIES_LIST:
      return { ...state, taskInquiriesList: action.payload.data };
    case SET_UNREAD_INUIRIES_COUNT: 
      return {...state,unreadInquiriesCount : action.payload.data }
    default:
      return state;
  }
};

export default Inquiries;
