import { SET_FORGETPASSWORD_FLAG,  SET_FORGETPASSWORD } from '../../actions/types/types';

const initialState={
    forgetPassword:{},
    forgetPasswordFlag:false
}

const ForgetPassword = (state = initialState, action) =>{
    switch(action.type){
        case SET_FORGETPASSWORD:
            return {...state , forgetPassword : action.payload.data };
            case SET_FORGETPASSWORD_FLAG:
                return { ...state , forgetPasswordFlag : action.payload.data}
                default:
                    return state;
    }
}

export default ForgetPassword;