
import {
    CURRENT_TRACKING_DETAILS
} from "../../actions/types/types";


const initState = {	
	
	current_tracking_data : {
		createdAt : 0,
        updatedAt: 0,
		id: "",  
		android_version: "",
		co_tasker_app_version: "",
		device_id: "",
		device_name: "",
		device_type: "",
		email: "",
		first_name: "",
		first_opened_when: 	null,		
		is_deleted: false,
		last_name: "",
		last_opened_when: 0,
		location: {},
		location_name: "",
		model_number: "",
		phone: "",
		user_id: {}
    },    
};

const VoucherReducers = (state = initState, action) => {	
	
	switch (action.type) {	
		case CURRENT_TRACKING_DETAILS:
            return { ...state, current_tracking_data: action.payload.data};	       
		default:
			return { ...state };
	}
	
};

export default VoucherReducers;
