import { store } from "../redux/storeConfig/store";

import { history } from "../app/AppRouter";
import { setLoginFlag, setLoginUser } from "../redux/actions/login/loginAction";
import { API_URL } from "../utility/globalConstants";

const axios = require("axios");

const mainUrl = API_URL;

export const api = async (endpoint, data, type) => {
  var res;
  var token = store.getState().login.loginUser.token;
  switch (type) {
    case "post":
      await axios({
        data: data,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },
        url: mainUrl + endpoint,
      })
        .then(function(response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("cotasker");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/login");
          } else {
            res = err.response;
          }
        });
      break;
    case "get":
      await axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },
        url: mainUrl + endpoint,
      })
        .then(function(response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("cotasker");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/login");
          } else {
            res = err.response;
          }
        });
      break;
    case "put":
      await axios({
        method: "put",
        data: data,
        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },
        url: mainUrl + endpoint,
      })
        .then(function(response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("cotasker");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/login");
          } else {
            res = err.response;
          }
        });
      break;
    case "patch":
      await axios({
        method: "patch",
        data: data,
        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },
        url: mainUrl + endpoint,
      })
        .then(function(response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("cotasker");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/login");
          } else {
            res = err.response;
          }
        });
      break;
    case "delete":
      await axios({
        data: data,
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },
        url: mainUrl + endpoint,
      })
        .then(function(response) {
          res = response;
        })
        .catch((err) => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("cotasker");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/login");
          } else {
            res = err.response;
          }
        });
      break;
    case "postWithoutToken":
      await axios({
        method: "post",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
        url: mainUrl + endpoint,
      })
        .then(function(response) {
          res = response;
        })
        .catch((err) => {
          res = err.response;
        });
      break;
    case "postFileDownload":
      await axios({
        method: "post",
        data: data,
        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },

        url: mainUrl + endpoint,
      })
        .then(function(response) {
          const url = window.URL.createObjectURL(
            new Blob([response.data.data])
          );
          // const url = window.URL.createObjectURL(response.data.data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          res = err.response;
        });
      break;
    default:
      return true;
  }

  // var parsdata = await checkData(res);
  return res;
};

export const download = async (endpoint, name) => {
  var myRequest = new Request(mainUrl + endpoint);
  fetch(myRequest)
    .then((response) => response.blob())
    .then(function(myBlob) {
      var objectURL = URL.createObjectURL(myBlob);
      const link = document.createElement("a");
      link.href = objectURL;
      link.setAttribute("download", `${name}.pdf`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};

export const checkData = (data) => {
  return true;
};
