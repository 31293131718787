import {
  SET_ALL_REASONS_LIST,
  SET_ALL_CANCEL_TASK_FEEDBACK_LIST,
  SET_ALL_DELETE_TASK_FEEDBACK_LIST,
  SET_ALL_REASONS_LIST_TYPE_D,
  SET_ALL_RATINGS_LIST,
  SET_ALL_TOTAL_RATINGS_LIST,
  SET_RATINGS_DOWNLOAD,
  SET_ALL_REASONS_LIST_TYPE_C,
  SET_ALL_REASONS_LIST_TYPE_CAT,
} from "../../actions/types/reasons/reasons";

const initialState = {
  getReasonsList: [],
  getRatingsList: [],
  cancelTaskFeedbackList: [],
  deleteTaskFeedbackList: [],
  getReasonsListTypeD: [],
  getReasonsListTypeC: [],
  getReasonsListTypeCAT: [],
  getRatingDownload: "",
  getTotalRatingList: {
    count: 0,
    data: [],
    pages: 1,
  },
};

const Reasons = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_REASONS_LIST:
      return { ...state, getReasonsList: action.payload.data };
    case SET_RATINGS_DOWNLOAD:
      return { ...state, getRatingDownload: action.payload.data };
    case SET_ALL_REASONS_LIST_TYPE_D:
      return { ...state, getReasonsListTypeD: action.payload.data };
    case SET_ALL_REASONS_LIST_TYPE_C:
      return { ...state, getReasonsListTypeC: action.payload.data };
    case SET_ALL_REASONS_LIST_TYPE_CAT:
      return { ...state, getReasonsListTypeCAT: action.payload.data };
    case SET_ALL_CANCEL_TASK_FEEDBACK_LIST:
      return { ...state, cancelTaskFeedbackList: action.payload.data };
    case SET_ALL_DELETE_TASK_FEEDBACK_LIST:
      return { ...state, deleteTaskFeedbackList: action.payload.data };
    case SET_ALL_RATINGS_LIST:
      return { ...state, getRatingsList: action.payload.data };
    case SET_ALL_TOTAL_RATINGS_LIST:
      console.log("SET_ALL_TOTAL_RATINGS_LIST", action.payload.data);
      return { ...state, getTotalRatingList: action.payload.data };

    default:
      return state;
  }
};

export default Reasons;
