import {
  CURRENT_TASK_DETAILS,
  SET_COMMENT_OF_TASK,
  SET_CURRENT_OFFER_DATA,
  SET_TASK_PAGE_HISTORY,
} from "../../actions/types/types";

const initialState = {
  current_task_data: {
    OffersDetails: [],
    budget_type: "S",
    budget_value: 0,
    category: "",
    comments: [],
    createdAt: 1572348963921,
    description: "Demo",
    id: "5db824232ec88827ea841174",
    is_deleted: false,
    is_expired: false,
    location: { type: "Point", coordinates: [] },
    location_name: "",
    must_have_list: "",
    no_of_hour_require: 0,
    no_of_taskers_assigned: 0,
    no_of_taskers_require: 1,
    offers: 0,
    relation: "",
    status: "P",
    is_task_to_be_considered_in_completion_ratio: false,
    how_tasker_affected: "Negatively",
    how_tascreateor_affected: "Negatively",
    task_assign_to: null,
    task_creator: {
      added_task_count: 0,
      address: "",
      average_rating: 0,
      category: null,
      coines_earned: 0,
      createdAt: 0,
      current_location: null,
      date_of_birth: "",
      deviceToken: null,
      device_type: "N",
      email: "",
      firstname: "",
      gender: "W",
      ibn_number: "",
      id: "5d93417bd61d016f4de6b9f2",
      image_document: "",
      image_profile: "",
      intro: "I am a BA",
      is_active: true,
      is_deleted: false,
      is_registered: true,
      languages: "English,German,Hindi",
      lastname: "Kapoor",
      notification: true,
      password: null,
      phone: "",
      preferences: "",
      rejectReason: null,
      requested_task_count: 0,
      resetPasswordToken: null,
      skills: [],
      status: "A",
      tax_id: "",
      type: "user",
      updatedAt: 1572360882098,
      wallent_balance: 0,
    },
    task_done_option: "",
    task_done_time: 0,
    task_image: [],
    task_live_time: 0,
    task_live_type: "",
    taskers: "",
    title: "",
    type_of_task: "",
    updatedAt: 0,
    extra_payment_ids: [],
    is_to_expire: true,
  },
  current_task_commnet: [],
  taskPageHistory: {
    page: 0,
    limit: 0,
    status: "All",
    category: "All",
    ifAll: true,
    startDate: "",
    endDate: "",
    start_date: "",
    end_date: "",
    search: "",
    id: "",
    selectedCities: [],
    isPostFromProfile: "",
  },
  current_offer_data: {
    OfferReplies: [],
    address: "",
    admin_asked_for_review_on_cancel: false,
    budget_type: "",
    budget_value: 0,
    business_address: "",
    business_name: "",
    createdAt: 0,
    description: "",
    discount: 0,
    extra_payment_accept_count: 0,
    extra_payment_accepted_total_amount_with_fee: 0,
    extra_payment_accepted_total_amount_without_fee: 0,
    extra_payment_accepted_total_cotasker_fee: 0,
    extra_payment_request_count: 0,
    extra_payment_requested_total_amount_with_fee: 0,
    extra_payment_requested_total_amount_without_fee: 0,
    extra_payment_requested_total_cotasker_fee: 0,
    fees: 0,
    full_name: "",
    id: "",
    invite_friends: null,
    isBusiness: false,
    isOrignal: true,
    is_assigned: false,
    is_deleted: false,
    is_extra_payment_accepted: false,
    is_extra_payment_requested: false,
    is_sensitive_cotain: false,
    location: {},
    masked_description: "",
    no_of_hour_require: 0,
    payable_fee: 0,
    payable_value: 0,
    refund_id: null,
    refund_to: "",
    status: "",
    task_id: "",
    task_value: 0,
    tax_number: "",
    transaction_id: null,
    updatedAt: 0,
    user_id: null,
    voucher_id: null,
    task_owner_info: {},
  },
};

const TaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_TASK_DETAILS:
      return { ...state, current_task_data: action.payload.data };
    case SET_COMMENT_OF_TASK:
      return { ...state, current_task_commnet: action.payload.data };
    case SET_TASK_PAGE_HISTORY:
      return { ...state, taskPageHistory: action.payload.data };
    case SET_CURRENT_OFFER_DATA:
      return { ...state, current_offer_data: action.payload.data };
    default:
      return state;
  }
};

export default TaskReducer;
