import { SET_ALL_MARKER_IMAGE } from '../../actions/types/types'


const initialState={
    
   markerImage:[]
}


const MarkerImageReducer=(state=initialState,action)=>{
    switch (action.type){
        
        case SET_ALL_MARKER_IMAGE:
            return{...state,markerImage:action.payload.data}
        default:
            return state;
    }
}

export default MarkerImageReducer;