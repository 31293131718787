import { GET_ALL_CATEGORY, GET_ALL_DOCUMENT, SET_C_PC_TYPE_CATEGORY } from "../../actions/types/types";

const initState = {
  category: [],
  imageDocument: [],
  allCategories: [],
};

const CategoryReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORY:
      return { ...state, category: action.payload.data };
    case GET_ALL_DOCUMENT:
      return { ...state, imageDocument: action.payload.data };
    case SET_C_PC_TYPE_CATEGORY:
      return { ...state, allCategories: action.payload.data };
    default:
      return { ...state };
  }
};

export default CategoryReducers;
