import {
  CURRENT_TASK_DETAILS,
  SET_COMMENT_OF_TASK,
  SET_CURRENT_OFFER_DATA,
  SET_TASK_PAGE_HISTORY,
} from "../types/types";

export const setCurrentTaskDetails = (value) => {
  return {
    type: CURRENT_TASK_DETAILS,
    payload: { data: value },
  };
};

export const setTaskCommnet = (value) => {
  return {
    type: SET_COMMENT_OF_TASK,
    payload: { data: value },
  };
};

export const setTaskPageHistory = (value) => {
  return {
    type: SET_TASK_PAGE_HISTORY,
    payload: { data: value },
  };
};

export const setCurrentOfferData = (value) => {
  return {
    type: SET_CURRENT_OFFER_DATA,
    payload: { data: value },
  };
};
