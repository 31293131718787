import {
  GET_ALL_UNASSIGNEDVOUCHERS,
  CURRENT_VOUCHER_DETAILS,
  GET_ALL_PARTNER_VOUCHERS,
} from "../../actions/types/types";

const initState = {
  vouchers: [],
  allPartnerVouchers: [],
  current_voucher_data: {
    createdAt: 0,
    updatedAt: 0,
    id: "",
    title: "",
    description: "",
    code: "",
    is_active: true,
    is_deleted: false,
    is_applicatble_on_fees: false,
    type: "",
    voucher_type: "",
    value: 0,
    is_unlimited: false,
    start_date: 0,
    end_date: 0,
    user_type: "",
    is_user_specific: false,
    applied_count: 0,
    redeemed_count: 0,
    is_for_new_users: false,
    is_user_assigned: false,
  },
  destination_type: [
    {
      label: "Post a task",
      value: "toPostTask",
    },
    {
      label: "Profile",
      value: "toProfile",
    },
    {
      label: "Edit profile page",
      value: "toEditProfile",
    },
    {
      label: "Settings",
      value: "toSettings",
    },
    {
      label: "Notifications",
      value: "toNotifications",
    },
    {
      label: "Task list",
      value: "toTaskList",
    },
    {
      label: "Send an inquiry as an email",
      value: "toSendEmailInquiry",
    },
    {
      label: "Request a call enquiry",
      value: "toRequestCallInquiry",
    },
    {
      label: "Co-Wallet",
      value: "toWallet",
    },
    {
      label: "Pending reviews",
      value: "toPendingReviews",
    },
    {
      label: "Messages",
      value: "toMessages",
    },
    {
      label: "My created task",
      value: "toCreateTask",
    },
    {
      label: "My Offers",
      value: "toMyOffers",
    },
    {
      label: "Voucher page in wallet",
      value: "toVoucherWalletPage",
    },
    {
      label: "Url",
      value: "toUrl",
    },
    {
      label: "Specific Task",
      value: "toSpecificTask",
    },
    {
      label: "Notifications In Settings",
      value: "toNotificationInSettings",
    },
    {
      label: "None",
      value: "",
    },
  ],
  default_selected_dtype: {
    label: "None",
    value: "",
  },
  default_selected_voucher: null,
};

const VoucherReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_UNASSIGNEDVOUCHERS:
      return { ...state, vouchers: action.payload.data };
    case GET_ALL_PARTNER_VOUCHERS:
      return { ...state, allPartnerVouchers: action.payload.data };
    case CURRENT_VOUCHER_DETAILS:
      return { ...state, current_voucher_data: action.payload.data };
    default:
      return { ...state };
  }
};

export default VoucherReducers;
