import {
  SET_IS_PLANS_LIST_TABLE_LOADING,
  SET_PLANS_LIST,
  SET_PLAN_CATEGORIES_BY_ID,
  SET_PLAN_GET_BY_ID,
  SET_PLAN_SELECTED_CATEGORIES,
} from "../../actions/types/plans";

const initialState = {
  plansList: null,
  isPlansListTableLoading: true,
  planGetById: {},
  planCategoriesById: [],
  selectedPlanCategories: [],
};

const PlansReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLANS_LIST:
      return { ...state, plansList: action.payload.data };
    case SET_IS_PLANS_LIST_TABLE_LOADING:
      return { ...state, isPlansListTableLoading: action.payload.data };
    case SET_PLAN_GET_BY_ID:
      return { ...state, planGetById: action.payload.data };
    case SET_PLAN_CATEGORIES_BY_ID:
      return { ...state, planCategoriesById: action.payload.data };
    case SET_PLAN_SELECTED_CATEGORIES:
      return { ...state, selectedPlanCategories: action.payload.data };
    default:
      return state;
  }
};

export default PlansReducers;
