
import {
	GET_ALL_FILTEREDDATA	
} from "../../actions/types/types";


const initState = {	
	filteredData:[]	
};

const searchBarReducers = (state = initState, action) => {
	switch (action.type) {				
		case GET_ALL_FILTEREDDATA:
			return { ...state, filteredData: action.payload.data};
			
		default:
			return { ...state };
	}
};

export default searchBarReducers;
