// import external modules
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// import internal(own) modules
// import registerServiceWorker from "./registerServiceWorker";
import { unregister } from './registerServiceWorker';
import { store } from "./redux/storeConfig/store";
import ReduxToastr from 'react-redux-toastr'
import { setLoginFlag, setLoginUser} from './redux/actions/login/loginAction';
// import {   } from './redux/actions/apiAction/apiAction';

import "font-awesome/css/font-awesome.min.css";
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';

import "./index.scss";
import "./assets/css/style.css"
import Spinner from "./components/spinner/spinner";
import LoaderComponent from "./components/loader/loader";
import { history } from "./app/AppRouter";
import ErrorBoundary from "./utility/boundary/ErrorBoundary";


const LazyApp = lazy(() => import("./app/AppRouter"));

const jsx = (
	
	<Provider store={store}>
		<Suspense fallback={<div>Loading...</div>}>		
			<ErrorBoundary>		
			<LazyApp />
			<ReduxToastr 
				timeOut={2000}
				newestOnTop={false}
				preventDuplicates
				position="top-right"
				transitionIn="fadeIn"
				transitionOut="fadeOut"
				progressBar
				closeOnToastrClick 
			/>
			<LoaderComponent />
			</ErrorBoundary>		
		</Suspense>
		
	</Provider>
	
)

let hasRendered = false;

const renderApp = async() => {
	// await fetch('/thing/stuck/in/cache', {method:'POST', credentials:'include'});
	if (!hasRendered) {
		ReactDOM.render(jsx, document.getElementById('root'));
		// registerServiceWorker();
		hasRendered = true;
	}
}

ReactDOM.render(<Spinner />, document.getElementById('root'));
unregister();


const renderLogin = () => {
	store.dispatch(setLoginFlag(false));
	renderApp();
	
	if(window.location.pathname === '/cotasker_admin/' || window.location.pathname === '/'){
		history.push('/login');
	}
	// history.push('/login');
}

 try {
	const login = localStorage.getItem('cotasker');	
		
	if (login) {		
		const loginObject = JSON.parse(login);		          
		store.dispatch(setLoginUser(loginObject));
		store.dispatch(setLoginFlag(true)) 
		
		
		renderApp();
		if(window.location.pathname === '/cotasker_admin/' || window.location.pathname === '/'){
			history.push('/user');
		}else{
			history.push(window.location.pathname)
		}	
		
	} else {	
		renderLogin()
	}
} catch (e) {
	
	renderLogin();
}
