
import {
	SET_DASHBOARD_CARD_DATA	
} from "../../actions/types/types";


const initState = {	
	cardData: {
		customer: 0,
    	butler: 0
	}	
};

const DashboardReducer = (state = initState, action) => {
	switch (action.type) {				
		case SET_DASHBOARD_CARD_DATA:
			return { ...state, cardData: action.payload.data};
			
		default:
			return { ...state };
	}
};

export default DashboardReducer;
